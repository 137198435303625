//////////  imported components /////
import React from "react"
import Header from "../components/header"
import { Provider } from "react-redux"
import store from "../helper/redux/store"
import LandingPage from "./landing"
import Footer from "../components/footer"

const IndexPage = (): any => {
  return (
    <>
      <Header isDarkMode={true} />
      <Provider store={store}>
        <main>
          <LandingPage />
        </main>
      </Provider>
      <Footer />
    </>
  )
}

export default IndexPage

import React from "react"
import Mailchimp from "../components/mailchimp"

import "../assets/styles/components/footer.scss"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="footer-top">
          <div className="top-left">
            <ul className="footer-list-items">
              <li className="list-item-header">Company</li>
              <Link to="/contact" className="list-item">
                Contact Us
              </Link>
              <Link to="/faq" className="list-item">
                FAQs
              </Link>
              {/* <Link to="/contact" className="list-item">Switch to Hosting</Link> */}
            </ul>
            <ul className="footer-list-items">
              <li className="list-item-header">Let’s Help You</li>
              <li className="list-item">
                <Link to="/doc/" target="_blank" rel="noopener noreferrer">
                  Blog
                </Link>
              </li>
            </ul>
            <ul className="footer-list-items">
              <li className="list-item-header">Quick Links</li>
              <Link to="/terms" className="list-item">
                Terms of Use
              </Link>
              <Link to="/policy" className="list-item">
                Privacy Policy
              </Link>
              <li className="list-item">Support</li>
            </ul>
          </div>
          <div className="top-right">
            <div className="newsletter-head">Newsletter</div>
            <Mailchimp />
            <div className="app-store-btns"></div>
          </div>
        </div>
        <div className="bottom-wrapper">
          <div className="footer-bottom">
            <div className="bottom-left">
              <span className="copyright-text">Copyright © 2022 Brooi. All Rights Reserved</span>
            </div>
            <div className="bottom-right">
              <a
                href="https://www.instagram.com/brooiproperties"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                    fill="#969EA4"
                  />
                  <g clipPath="url(#clip0_748_6393)">
                    <path
                      d="M20.0043 15.9568C17.7329 15.9568 15.9007 17.7604 15.9007 19.9963C15.9007 22.2322 17.7329 24.0357 20.0043 24.0357C22.2757 24.0357 24.1079 22.2322 24.1079 19.9963C24.1079 17.7604 22.2757 15.9568 20.0043 15.9568ZM20.0043 22.6225C18.5364 22.6225 17.3364 21.4447 17.3364 19.9963C17.3364 18.5479 18.5329 17.3701 20.0043 17.3701C21.4757 17.3701 22.6722 18.5479 22.6722 19.9963C22.6722 21.4447 21.4722 22.6225 20.0043 22.6225ZM25.2329 15.7916C25.2329 16.3154 24.8043 16.7338 24.2757 16.7338C23.7436 16.7338 23.3186 16.3119 23.3186 15.7916C23.3186 15.2713 23.7472 14.8494 24.2757 14.8494C24.8043 14.8494 25.2329 15.2713 25.2329 15.7916ZM27.9507 16.7479C27.89 15.4857 27.5972 14.3678 26.6579 13.4467C25.7222 12.5256 24.5864 12.2373 23.3043 12.174C21.9829 12.1002 18.0222 12.1002 16.7007 12.174C15.4222 12.2338 14.2864 12.5221 13.3472 13.4432C12.4079 14.3643 12.1186 15.4822 12.0543 16.7443C11.9793 18.0451 11.9793 21.9439 12.0543 23.2447C12.115 24.5068 12.4079 25.6248 13.3472 26.5459C14.2864 27.467 15.4186 27.7553 16.7007 27.8186C18.0222 27.8924 21.9829 27.8924 23.3043 27.8186C24.5864 27.7588 25.7222 27.4705 26.6579 26.5459C27.5936 25.6248 27.8864 24.5068 27.9507 23.2447C28.0257 21.9439 28.0257 18.0486 27.9507 16.7479ZM26.2436 24.6404C25.965 25.3295 25.4257 25.8603 24.7222 26.1381C23.6686 26.5494 21.1686 26.4545 20.0043 26.4545C18.84 26.4545 16.3364 26.5459 15.2864 26.1381C14.5864 25.8639 14.0472 25.333 13.765 24.6404C13.3472 23.6033 13.4436 21.1424 13.4436 19.9963C13.4436 18.8502 13.3507 16.3857 13.765 15.3521C14.0436 14.6631 14.5829 14.1322 15.2864 13.8545C16.34 13.4432 18.84 13.5381 20.0043 13.5381C21.1686 13.5381 23.6722 13.4467 24.7222 13.8545C25.4222 14.1287 25.9614 14.6596 26.2436 15.3521C26.6614 16.3893 26.565 18.8502 26.565 19.9963C26.565 21.1424 26.6614 23.6068 26.2436 24.6404Z"
                      fill="#26333F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_748_6393">
                      <rect width="16" height="18" fill="white" transform="translate(12 11)" />
                    </clipPath>
                  </defs>
                </svg>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/brooi-technology/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                    fill="#969EA4"
                  />
                  <g clipPath="url(#clip0_748_6396)">
                    <path
                      d="M14.9341 27.5011H11.2903V15.8175H14.9341V27.5011ZM13.1103 14.2237C11.9451 14.2237 11 13.2628 11 12.1026C11 11.5454 11.2223 11.0109 11.6181 10.6169C12.0138 10.2228 12.5506 10.0015 13.1103 10.0015C13.6699 10.0015 14.2067 10.2228 14.6025 10.6169C14.9982 11.0109 15.2205 11.5454 15.2205 12.1026C15.2205 13.2628 14.2751 14.2237 13.1103 14.2237ZM28.5718 27.5011H24.9358V21.8136C24.9358 20.4581 24.9084 18.7198 23.0414 18.7198C21.1469 18.7198 20.8565 20.1925 20.8565 21.7159V27.5011H17.2166V15.8175H20.7114V17.4112H20.7624C21.2489 16.4933 22.4372 15.5245 24.2101 15.5245C27.8978 15.5245 28.5758 17.9425 28.5758 21.0831V27.5011H28.5718Z"
                      fill="#26333F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_748_6396">
                      <rect width="17.5758" height="20" fill="white" transform="translate(11 10)" />
                    </clipPath>
                  </defs>
                </svg>{" "}
              </a>
              <a href="https://www.facebook.com/profile.php?id=61567728985108&mibextid=ZbWKwL" target="_blank" className="social-icon" rel="noreferrer">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                    fill="#969EA4"
                  />
                  <path
                    d="M24.4668 21.6875L25 18.2489H21.6659V16.0176C21.6659 15.0768 22.1317 14.1599 23.6249 14.1599H25.1407V11.2323C25.1407 11.2323 23.7652 11 22.45 11C19.7043 11 17.9095 12.6469 17.9095 15.6283V18.2489H14.8574V21.6875H17.9095V30H21.6659V21.6875H24.4668Z"
                    fill="#26333F"
                  />
                </svg>{" "}
              </a>
              {/* <a href="https://twitter.com/BrooiHQ" className="social-icon" target="_blank" rel="noreferrer">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                    fill="#969EA4"
                  />
                  <path
                    d="M27.1498 16.5184C27.1612 16.6728 27.1612 16.8272 27.1612 16.9816C27.1612 21.6912 23.4493 27.1176 16.665 27.1176C14.5749 27.1176 12.6333 26.5331 11 25.5184C11.297 25.5515 11.5825 25.5625 11.8909 25.5625C13.6155 25.5625 15.2031 25 16.4708 24.0405C14.849 24.0074 13.4898 22.9816 13.0216 21.5699C13.25 21.6029 13.4784 21.625 13.7183 21.625C14.0495 21.625 14.3807 21.5809 14.6891 21.5037C12.9987 21.1728 11.7309 19.739 11.7309 18.0073V17.9632C12.222 18.228 12.7932 18.3934 13.3984 18.4154C12.4048 17.7757 11.7538 16.6838 11.7538 15.4485C11.7538 14.7868 11.9365 14.1801 12.2563 13.6507C14.0723 15.8125 16.802 17.2242 19.8629 17.3787C19.8058 17.114 19.7716 16.8382 19.7716 16.5625C19.7716 14.5992 21.4162 13 23.4607 13C24.5228 13 25.4822 13.4301 26.1561 14.125C26.9898 13.9706 27.7893 13.6728 28.4975 13.2647C28.2233 14.0919 27.6409 14.7868 26.8756 15.2279C27.618 15.1508 28.3376 14.9522 29 14.6765C28.4975 15.3823 27.8693 16.011 27.1498 16.5184Z"
                    fill="#26333F"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
